export default {
  api: 'https://cx-qa-api.curvolabs.com/graphql',
  agGridLicense:
    '',
  supplierPortalBaseUrl: 'https://curvolabstest.appiancloud.com',
  authDomain: 'curvo-qa.us.auth0.com',
  authClientId: 'oSnYXgTpvyCdKvlZS9RRFpdoznBxCSHN',
  authAudience: 'https://curvo-qa.us.auth0.com/api/v2/',
  useHubspot: false,
  useHeap: false,
  launchDarklyClientId:
    '642a8c28bdd73812d6f1d333',
}
